export const HARMONYONEISSUERLINK =
  "https://a4zgkuig3dwmanlwztdihcbx3j7adyae7f6hzxuuqf52kiwwl4.arweave.net/BzJlUQbY7MA1dszGg4g32n_4B4AT5fHzelIF7pSLWX8";
export const HARMONYONEDAOLINK =
  "https://3dri7n6qayc6xe44cihxiixehoyx3y3ldyzrkz7ebfmdo6tufu.arweave.net/2OKPt9AGBeuTnBIPdC-LkO7F942seMxVn5AlYN3p0LQ";

export const POLYGONISSUERLINK =
  "https://tpou22egyefsxslyvnjmlq2izsfpmo54epsbrvgvjezndde2.arweave.net/m91NaIbB_CyvJeKtSxcNIzIr2O7wj5BjU1Uk_y0Yyak";

export const POLYGONDAOLINK =
  " https://flmywpggg4vzssdsmkmyu7uhsc6fxr3bpo7g2imr644elzk4l67q.arweave.net/KtmLPMY3K5lIcmKZin6HkLxbx2F7vm0hkfc4ReVcX78";
